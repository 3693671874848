//libs
// import smoothScroll from './libs/smoothscroll';
import gsap from 'gsap';

//components
// import Menu from './components/menu';
// import Form from './components/form';
// import Pages from './components/pages';

// new Menu();
// new Form();
// new Pages();

gsap.to('.background-block', {delay: 0.2,yPercent: 100,ease: 'bounce.out',stagger: 0.2,duration: 2,onComplete: () => {// tl.play();gsap.to('.main-heading span', {rotate: 180,ease: 'bounce.out',duration: 1.5,})}
})